@import '../../../../styles/customMediaQueries.css';

.root {

  &[id*="how-it-works-step-"] {
    position: relative;
    margin-bottom: 40px;

    @media (--viewportLarge) {
      margin-bottom: 0;
    }
    
    @media (--viewportLarge) {
      display: flex;
      align-items: flex-start;
    }

    & [class*="AspectRatioWrapper_aspectPadding"] {
      padding-bottom: 60px !important;
    }

    & [class*="BlockDefault_media"] {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      margin-right: 20px;
      position: absolute;
      left: 0;
      top: -18px;

      @media (--viewportLarge) {
        position: static;
        margin-top: -18px;
      }
    }

    & [class*="BlockDefault_text"] {
      margin-top: 0;

      @media (--viewportLarge) {
        max-width: 250px;
      }
    }

    & [class*="Heading"] {
      margin: 8px 0 30px;
      padding: 0;
      color: #45A8D4;
      font-size: 18px;
      padding-left: 80px;

      @media (--viewportLarge) {
        margin: 0 0 8px;
        padding-left: 0;
      }
    } 

    & p {
      padding: 0;
      margin: 0;
    }
  }


  &[id*="list-watercraft-step-"] {
    padding: 30px 25px;
    background: rgba(69, 168, 212, 0.10);
    border-radius: 8px;

    @media (--viewportLarge) {
      padding: 30px 25px 30px 60px;
    }


    & [class*="Heading"] {
      margin: 0 0 8px;
      padding: 0;
      position: relative;
      color: #45A8D4;
      font-size: 18px;
      display: flex;
      align-items: center;

      &:before {
        content: "1";
        display: block;
        margin-right: 12px;
        text-shadow: -1.5px 0 #45A8D4, 0 1.5px #45A8D4, 1.5px 0 #45A8D4, 0 -1.5px #45A8D4;
        color: #fff;
        font-size: 36px;
        line-height: 1;
        transform: translateY(-3px);

        @media (--viewportLarge) {
          position: absolute;
          left: -25px;
          top: -5px;
          margin-right: 0;
          transform: translateY(0);
        }
      }

    } 

    &:nth-child(2) [class*="Heading"]::before {
      content: "2";
      left: -30px;
    }

    &:nth-child(3) [class*="Heading"]::before {
      content: "3";
      left: -30px;
    }
  }


  &[id*="why-upisle"] {
    padding-bottom: 60px;

    & [class*="Link_link"] {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 254px;
      min-height: 60px;
      border-radius: 8px;

      font-size: 16px;
    }
  }
}








