@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}
.threeColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}
.fourColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.ctaButton {
  text-align: left;
  justify-self: start;

  display: inline-block;
  font-size: 15px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;
  color: white;
  text-decoration: none;
  
  font-weight: 500;
  
  box-shadow: none;
  width: 250px;
  padding: 20px 20px;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  border-radius: 15px;
  text-align: center;

  cursor: pointer;


  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    text-align: center;
    justify-self: center;
    width: 400px;
  }
}