@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }

}

.rootWithSeparator {
  &:after {
    content: "";
    display: block;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    width: 100%;
    max-width: 75%;
    height: 2px;
    border-radius: 2px;
    background-color: var(--marketplaceColor);
    z-index: 1;

    @media (--viewportMedium) {
      max-width: 60%;
    }

  }

  &:first-of-type {
    @media (--viewportMedium) {
      &::after {
        display: none;
      }
    }
  }

  &:last-of-type {
    &::after {
      display: none;
    }
  }
}

.sectionContent {
  padding: 30px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 60px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

& :global(#how-it-works-for-renters) {

  & [class*="SectionBuilder_blockContainer"] {
    display: block;
    
    @media (--viewportLarge) {
      display: grid;
    }
  }
}

& :global(#how-it-works-upisle) {
  & header {
    justify-content: center;
  }
}

& :global(#how-it-works-renters),
& :global(#how-it-works-owners) {
  text-align: center;

  & header {
    justify-content: center;

    & a {
      box-shadow: none;
      width: 250px;
      padding: 20px 20px;
      margin: 0;
      margin-left: auto;
      margin-right: auto;

      font-size: 18px;
      border-radius: 15px;
      text-align: center;

      @media (--viewportMedium) {
        width: 400px;
      }
    }
  }

  &:last-of-type {
    padding-bottom: 48px;
  }

  & [class*="BlockDefault_media"] {
    max-width: 120px;
    margin-left: auto;
    margin-right: auto;
  }
}

& :global(#popular-rentals),
& :global(#locations-coming-soon),
& :global(#locations),
& :global(#upisle-rentals-customer-reviews),
& :global(#popular-upisle-rentals) {
  overflow: hidden;
  padding-bottom: 2px;
}
