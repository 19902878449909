@import '../../../../styles/customMediaQueries.css';

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.backgroundImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

:global(#hero) {

  height: calc(100vh - 60px);

  @media (--viewportMedium) {
    height: auto;
  }

  @media (--viewport1770) {
    min-height: 720px;
  }

  &:after {
    display: none;
  }

  background-image: url('../../../../assets/hero-mobile-bg.avif');
  background-repeat: no-repeat;
  background-position: center;
  
  @media (--viewportSmall) {
    background-size: cover;
  }

  @media (--viewportMedium) {
    /* position: absolute; */
    background-image: url('../../../../assets/hero-desktop-bg.avif');
  }
  
  @media (--viewportLarge) {
    background-size: 110%;
    background-position: 0 60%;
  }

  @media (--viewport1280) {
    background-position: bottom -80px center;
    background-size: cover;
  }

  & img {
    display: block;
    
    @media (--viewportMedium) {
      display: none;
    }
  }
}